import './App.scss';
import {ChakraProvider} from "@chakra-ui/react"
import {Stepper} from './comnponents/Stepper';
import {IntlProvider} from "react-intl";
import German from './lang/de.json';
import French from './lang/fr.json';
import English from './lang/en.json';
import Finish from './lang/fi.json';
import Danish from './lang/dk.json';

import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/nl';
import 'moment/locale/fi';
import 'moment/locale/da';
import ChakraTheme from "./theme/Chakra";

const currentUrl = window.location.toString();

let messages = German;
let locale = 'de';
let momentLocale = 'de';

switch (true) {
    case (currentUrl.search('/fr') !== -1):
        messages = French;
        locale = 'fr';
        momentLocale = 'fr';
        break;

    case (currentUrl.search('/gb') !== -1):
        messages = English;
        locale = 'en';
        momentLocale = 'en-gb';
        break;

    case (currentUrl.search('/fi') !== -1):
        messages = Finish;
        locale = 'fi';
        momentLocale = 'fi';
        break;

    case (currentUrl.search('/dk') !== -1):
        messages = Danish;
        locale = 'dk';
        momentLocale = 'da';
        break;

    case (currentUrl.search('/ie') !== -1):
        messages = English;
        messages.outgoingUrl = 'https://buyvcapsules.com/ie/buy-now.html';
        locale = 'en';
        momentLocale = 'en-gb';
        break;

    case (currentUrl.search('/at') !== -1):
        messages = German;
        messages.outgoingUrl = 'https://buyvcapsules.com/at/jetzt-kaufen.html';
        locale = 'de';
        break;

    case (currentUrl.search('/ch') !== -1):
        messages = German;
        messages.outgoingUrl = 'https://v-capsules-shop.com/ch/jetzt-kaufen.html';
        locale = 'de';
        break;

    default:
}

moment.locale(momentLocale);

document.title = 'VOLT ' + messages.title;

function App() {
    return (
        <div className="App">
            <IntlProvider locale={locale} messages={messages} defaultLocale="de">
                <ChakraProvider theme={ChakraTheme}>
                    <Stepper/>
                </ChakraProvider>
            </IntlProvider>
        </div>
    );
}

export default App;
